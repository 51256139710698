<template>
    <div>
        <el-row :gutter="10">


            <el-col :span="24">
                <el-card>

                    <!-- 年月日选项 -->
                    <div class="container">
                        <div>
                            <div class="date" :class="{ 'year': isSelected === 'year', 'dark': isSelected !== 'year' }"
                                ref="dateYear" @click="handleDateClick('year')">
                                年
                            </div>
                            <div class="date"
                                :class="{ 'month': isSelected === 'month', 'dark': isSelected !== 'month' }"
                                ref="dateMonth" @click="handleDateClick('month')">
                                月
                            </div>
                            <div class="date" :class="{ 'day': isSelected === 'day', 'dark': isSelected !== 'day' }"
                                ref="dateDay" @click="handleDateClick('day')">
                                日
                            </div>

                        </div>
                    </div>

                    <!-- 指标选项 -->
                    <div class="pointContainer">
                        <div>
                            <div class="point" :ref="'pointItem-' + pointItem.pointId" v-for="pointItem in pointList"
                                :key="pointItem.pointId" @click="handlePointClick(pointItem.pointId)"
                                :class="{'pointSelected': selectedPointId === pointItem.pointId}">
                                {{ pointItem.pointName}}
                            </div>
                        </div>
                    </div>


                    <div ref="powerTrendChart" class="chart-box">


                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import * as echarts from "echarts";
    import http from '@/config/http';

    export default {

        data() {
            return {

                pointArr: [],
                pointList: [],
                pointTitle: '',
                pointUnit: '',
                isSelected: 'day',
                selectedPointId: null,
                pointId: 0,


                psNameData: [],
                timeData: [],
                seriesData: []

            }
        },

        mounted() {
            this.getPointInfo()

        },
        computed: {
            // 创建一个计算属性来映射pointId到pointName  
            pointNameMap() {
                return this.pointList.reduce((map, item) => {
                    map[item.pointId] = item.pointName;
                    return map;
                }, {});
            },

             // 创建一个计算属性来映射pointId到showUnit  
             pointShowUnitMap() {
                return this.pointList.reduce((map, item) => {
                    map[item.pointId] = item.showUnit;
                    return map;
                }, {});
            },
        },
        methods: {
            handleDateClick(type) {
                // 更新isSelected以反映被点击的日期部分  
                this.isSelected = type;
                this.initData()
            },

            getPointNameByPointId(pointId) {
                // 直接从映射对象中获取  
                return this.pointNameMap[pointId] || '未找到对应的pointName';
            },

            getShowUnitByPointId(pointId) {
                // 直接从映射对象中获取  
                return this.pointShowUnitMap[pointId] || '未找到对应的展示单位';
            },

            handlePointClick(pointId) {
                // alert(pointId)
                this.selectedPointId = pointId; // 更新选中的点
                this.pointTitle = this.getPointNameByPointId(this.selectedPointId)
                this.pointUnit = this.getShowUnitByPointId(this.selectedPointId)
                this.initData()
            },

            getPointInfo() {
                let params = {}


                http.get('/index/getPSHistoryPoint', {
                        params
                    })
                    .then(data => {
                        this.pointArr = data.res.pointArr
                        this.pointList = data.res.pointList

                        if (this.selectedPointId == null) {
                            this.selectedPointId = this.pointList.length > 0 ? this.pointList[0].pointId : null
                            this.pointTitle = this.getPointNameByPointId(this.selectedPointId)
                            this.pointUnit = this.getShowUnitByPointId(this.selectedPointId)
                        }

                        this.initData()
                    })
                    .catch(error => {
                        console.error('请求失败:', error);
                        this.loading = false
                        // 处理错误  
                    });
            },

            initData() {
                let params = {}
                params.point_id = this.selectedPointId

                if (this.isSelected == 'year') {
                    params.query_type = 3
                } else if (this.isSelected == 'month') {
                    params.query_type = 2
                } else {
                    params.query_type = 1
                }

                http.get('/index/getPSHistoryChartData', {
                        params
                    })
                    .then(data => {
                        this.psNameData = data.res.psNameData
                        this.timeData = data.res.timeData
                        this.seriesData = data.res.seriesData

                        this.initCharts();
                    })
                    .catch(error => {
                        console.error('请求失败:', error);
                        this.loading = false
                        // 处理错误  
                    });
            },
            initCharts() {
                // 发电趋势图表
                const powerTrendChart = echarts.init(this.$refs.powerTrendChart);
                powerTrendChart.setOption({

                    // 图表配置和数据
                    title: {
                        text: this.pointTitle,
                        right: '6px'
                    },

                    legend: {
                        data: this.psNameData
                    },

                    tooltip: {
                        trigger: 'axis', // 或者 'axis'，取决于你的图表类型和数据结构  
                        axisPointer: {
                            type: 'cross'
                        },
                        formatter: function (params) {
                            let data = params[0]
                            return '时间: ' + data.axisValue + ', pv: ' + data.value; // 返回自定义的提示框内容  
                        }
                    },
                    xAxis: {
                        type: 'category',
                        splitLine: {
                            show: true
                        },
                        data: this.timeData

                    },
                    yAxis: {
                        type: "value",
                        name: this.pointUnit
                    },
                    series: this.seriesData
                });

            },
        }

    }
</script>

<style lang="scss">
    .container {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        left: 2em;
        top: 4em;
        z-index: 2;
        width: 6em;
    }

    .pointContainer {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        right: 2em;
        top: 6em;
        z-index: 2;
        font-size: 12px;
        font-weight: 500;
        width: 12em;
        text-align: left;
    }



    .date {
        width: 5em;
        border-radius: 4px;
        cursor: pointer;
        border: 1px solid #DCDFE6;
        color: #FFF;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        padding: .2em;
        margin-bottom: .4em;
    }

    .point {
        border-radius: 4px;
        cursor: pointer;
        border: 1px solid #DCDFE6;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        padding: .3em;
        margin-bottom: .4em;
    }

    .point.pointSelected {
        background-color: #5470C6;
        color: white;
    }

    .dark {
        opacity: .3;
        background-color: grey;
    }

    .year {
        background-color: #FAC858;
    }

    .month {
        background-color: #92CC76;
    }


    .day {
        background-color: #5470C6;
    }

    .date-text {
        display: inline-block;
        margin-left: 10px;

        /* 当不是选中状态时应用dark样式 */
        &.dark {
            opacity: 0.5;

        }
    }
</style>