// src/http.js  
import axios from 'axios';  
  
// 创建一个axios实例  
const http = axios.create({  
  baseURL: 'http://1.13.253.120:9000', // 你的Spring Boot后端地址  
  // baseURL: 'http://localhost:9000', // 你的Spring Boot后端地址  
  timeout: 10000, // 请求超时时间  
  headers: {'Content-Type': 'application/json'}  
});  
  
// 可以在这里添加请求拦截器和响应拦截器  
  
// 请求拦截器  
http.interceptors.request.use(  
  config => {  
    // 在发送请求之前做些什么  
    // 例如，可以在这里设置token  
    // if (store.getters.token) {  
    //   config.headers['Authorization'] = `Bearer ${store.getters.token}`  
    // }  
    return config;  
  },  
  error => {  
    // 对请求错误做些什么  
    return Promise.reject(error);  
  }  
);  
  
// 响应拦截器  
http.interceptors.response.use(  
  response => {  
    // 对响应数据做点什么  
    return response.data; // 假设后端已经处理了状态码，并直接返回了数据  
  },  
  error => {  
    // 对响应错误做点什么  
    if (error.response && error.response.status) {  
      // 根据状态码做不同的处理  
      console.log(error.response.status);  
      // 例如，如果状态码是401，可能表示未授权，需要跳转到登录页面  
    }  
    return Promise.reject(error);  
  }  
);  
  
export default http;