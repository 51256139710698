<template>
    <div>
        <h3 style="margin-top: 2em;">逆变器列表</h3>

        <el-row :gutter="10" style="margin-top: 1em; box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);">
            <el-table :data="inverterData" v-loading="loading" style="width: 100%; padding: 1em;">

                <el-table-column type="index" label="序号" min-width="100" max-width="1200"></el-table-column>

                <el-table-column prop="psKey" label="逆变器编号" min-width="100" max-width="1200"></el-table-column>
                <el-table-column prop="psId" label="电站Id" min-width="100" max-width="1200"></el-table-column>
                <el-table-column prop="deviceSn" label="SN编码" min-width="100" max-width="1200"></el-table-column>
                <el-table-column prop="deviceName" label="设备名称" min-width="100" max-width="1200"></el-table-column>
                <el-table-column prop="factoryName" label="所属企业" min-width="100" max-width="1200"></el-table-column>

                <el-table-column prop="devStatus" label="状态" min-width="100" max-width="1200">
                    <template slot-scope="scope">
                        <span v-if="scope.row.devStatus === '1'">
                            <el-tag type="success"> 正常</el-tag>
                        </span>
                        <span v-else-if="scope.row.devStatus === '0'">
                            <el-tag type="danger"> 异常</el-tag>
                        </span>
                        <span v-else>未知</span>
                    </template>

                </el-table-column>

                <el-table-column prop="dataUpdatedAt" label="数据更新时间" min-width="100" max-width="300"></el-table-column>
                <el-table-column label="操作" min-width="100" max-width="1200">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="handleInverterEdit(scope.row)">选择</el-button>
                    </template>

                </el-table-column>
            </el-table>

            <el-pagination style="text-align: center; padding: .6em" @size-change="handleSizeChange"
                @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[5, 10, 20]"
                :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
        </el-row>



    </div>
</template>

<script>
    import http from '@/config/http';
    export default {
        props: {
            psId: {
                type: Number,
                required: false
            }
        },
        data() {
            return {
                inverterData: [],
                params: {},
                loading: true,
                currentPage: 1,
                pageSize: 5,
                totalCount: 0,
            }

        },
        watch: {
            psId(newVal) {
                if (newVal) {
                    this.initData(newVal);
                }
            }
        },
        mounted() {
            this.initData();
        },
        methods: {
            handleInverterEdit(row) {
                console.log(row)
                this.$emit('update:psKey', row.psKey);
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.getInverterInfo(this.params)
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.getInverterInfo(this.params)
            },

            initData(psId) {
                if (psId == null) {
                    this.getInverterInfo(this.params)
                } else {
                    this.params.psId = psId
                    this.getInverterInfo(this.params)
                }
            },

            getInverterInfo(params) {
                this.loading = true

                params.page = this.currentPage
                params.limit = this.pageSize
                http.get('/index/getInverterPage', {
                        params
                    })
                    .then(data => {
                        this.inverterData = data.page.list
                        this.loading = false
                        this.currentPage = data.page.currPage
                        this.totalCount = data.page.totalCount
                    })
                    .catch(error => {
                        console.error('请求失败:', error);
                        this.loading = false
                        // 处理错误  
                    });


            },
        }
    }
</script>

<style>

</style>