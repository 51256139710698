<template>
    <div>
        <el-row :gutter="10">
            <el-col :span="24">
                <el-card>
                    <div ref="powerRealTimeChart" class="chart-box">
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import * as echarts from "echarts";
    import http from '@/config/http';

    export default {
        props: {
            psId: {
                type: Number,
                required: false
            }
        },

        data() {
            return {
                psName: '',
                legendData: [],
                timeData: [],
                seriesData: [],
                selected: {}
            }
        },

        mounted() {
            this.initData()
        },

        methods: {

            initData(psId) {

                let params = {}
                if (psId != null) {
                    params.psId = psId
                }

                http.get('/index/getPSRealTimeChartData', {
                        params
                    })
                    .then(data => {
                        this.legendData = data.res.legendData
                        this.timeData = data.res.timeData
                        this.seriesData = data.res.seriesData
                        this.psName = data.res.psName

                        // 遍历legendData，设置第一个为true，其余为false  
                        this.legendData.forEach((item, index) => {
                            const key = `${item}`; // 或者使用 item.toString()  
                            this.selected[key] = index === 0; // 如果索引为0，则为true，否则为false  
                        });

                        this.initCharts();
                    })
                    .catch(error => {
                        console.error('请求失败:', error);
                        this.loading = false
                        // 处理错误  
                    });
            },

            initCharts() {
                // 发电趋势图表
                const powerRealTimeChart = echarts.init(this.$refs.powerRealTimeChart);

                powerRealTimeChart.setOption({
                    // 图表配置和数据
                    title: {
                        text: [this.psName , '今日实时数据'].join('\n'),               
                        left: 'right'
                    },
                    legend: {
                        data: this.legendData,
                        selected: this.selected
                    },
                    tooltip: {
                        trigger: 'axis', // 或者 'axis'，取决于你的图表类型和数据结构  
                        axisPointer: {
                            type: 'cross'
                        },
                        formatter: function (params) {
                            let data = params[0]
                            return '时间: ' + data.axisValue + ', pv: ' + data.value; // 返回自定义的提示框内容  
                        }
                    },
                    yAxis: {
                        type: "value",
                    },
                    xAxis: {
                        type: 'category',
                        splitLine: {
                            show: true
                        },
                        data: this.timeData

                    },

                    series: this.seriesData
                });

                this.legendselectchanged(powerRealTimeChart)





            },

            legendselectchanged(powerRealTimeChart) {
                // 监听 legendselectchanged 事件  
                powerRealTimeChart.on('legendselectchanged', function (params) {
                    var legend = powerRealTimeChart.getOption().legend;
                    var selected = this.selected || {};

                    // 遍历所有图例项，取消除当前选中项外的所有项的选中状态  
                    legend[0].data.forEach(function (name) {
                        if (name !== params.name) {
                            selected[name] = false;
                        }
                    });

                    // 更新图例选中状态  
                    powerRealTimeChart.setOption({
                        legend: {
                            selected: selected
                        }
                    });
                });

            }




        },

        watch: {
            psId(newVal) {

                console.log('watch', newVal)
                if (newVal) {
                    this.initData(newVal);
                }
            }
        },

    }
</script>

<style>

</style>