<template>
    <div>
        <el-row :gutter="10" style="margin-top: 1em; box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);">
            <el-form :inline="true" :model="formInline" class="demo-form-inline" style="margin: 1em 1em 0 1em;">
                <el-form-item label="电站查询">
                    <el-input v-model="formInline.psName" placeholder="电站名称"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="onSubmit">查询</el-button>
                </el-form-item>
            </el-form>

            <!-- 电站基本信息表格 -->
            <el-table :data="powerStationData" v-loading="loading" style="width: 100%; padding: 1em;">
                <!-- 表格列定义 -->
                <el-table-column type="index" label="序号" min-width="100" max-width="1200"></el-table-column>
                <el-table-column prop="psName" label="电站名称" min-width="100" max-width="1200"></el-table-column>
                <el-table-column prop="psId" label="电站Id" min-width="100" max-width="1200"></el-table-column>
                <el-table-column prop="psType" label="电站类型" min-width="100" max-width="1200">
                    <template slot-scope="scope">
                        <span v-if="scope.row.psType === 1">
                            <el-tag> 地面电站 </el-tag>
                        </span>
                        <span v-else-if="scope.row.psType === 3">
                            <el-tag> 分布式光伏 </el-tag>
                        </span>
                        <span v-else-if="scope.row.psType === 4">
                            <el-tag> 户用光伏 </el-tag>
                        </span>
                        <span v-else-if="scope.row.psType === 5">
                            <el-tag> 户用储能 </el-tag>
                        </span>
                        <span v-else-if="scope.row.psType === 6">
                            <el-tag> 村级电站 </el-tag>
                        </span>
                        <span v-else-if="scope.row.psType === 7">
                            <el-tag> 分布式储能 </el-tag>
                        </span>
                        <span v-else-if="scope.row.psType === 8">
                            <el-tag> 扶贫电站 </el-tag>
                        </span>
                        <span v-else-if="scope.row.psType === 9">
                            <el-tag> 风能电站 </el-tag>
                        </span>
                        <span v-else-if="scope.row.psType === 12">
                            <el-tag> 工商业储能 </el-tag>
                        </span>
                        <span v-else>
                            <el-tag type="danger"> 未知类别 </el-tag>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="installedPower" label="装机功率" min-width="100" max-width="1200">
                </el-table-column>
                <el-table-column prop="provinceName" label="省份" min-width="100" max-width="1200"></el-table-column>
                <el-table-column prop="timezone" label="时区" min-width="100" max-width="1200"></el-table-column>


                <el-table-column prop="gridConnectionType" label="并网类型" min-width="100" max-width="1200">
                    <template slot-scope="scope">
                        <span v-if="scope.row.gridConnectionType === 1">
                            <el-tag> 全额上网</el-tag>
                        </span>
                        <span v-else-if="scope.row.gridConnectionType === 2">
                            <el-tag> 自发自用，余电上网</el-tag>
                        </span>
                        <span v-else-if="scope.row.gridConnectionType === 3">
                            <el-tag> 自发自用，无馈网</el-tag>
                        </span>
                        <span v-else-if="scope.row.gridConnectionType === 4">
                            <el-tag> 离网</el-tag>
                        </span>
                        <span v-else>
                            <el-tag type="danger"> 未知类型 </el-tag>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="gridConnectionDate" label="接入时间" min-width="100" max-width="1200">
                </el-table-column>

                <el-table-column prop="psStatus" label="电站状态" min-width="100" max-width="1200">

                    <template slot-scope="scope">
                        <span v-if="scope.row.psStatus === 1">
                            <el-tag type="success"> 正常</el-tag>
                        </span>
                        <span v-else-if="scope.row.psStatus === 0">
                            <el-tag type="danger"> 异常</el-tag>
                        </span>
                        <span v-else>未知</span>
                    </template>
                </el-table-column>
                <el-table-column prop="psLocation" label="电站地址" min-width="100" max-width="1200"></el-table-column>

                <el-table-column label="操作" min-width="100">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="handlePSEdit(scope.$index, scope.row)">选择</el-button>
                    </template>

                </el-table-column>

            </el-table>
            <!-- 分页区域 -->
  
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="currentPage" :page-sizes="[ 5, 10, 20]" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="totalCount" style="text-align: center;">
            </el-pagination>

        </el-row>
    </div>
</template>

<script>
    import http from '@/config/http';

    export default {

        data() {
            return {
                // 其他统计数据...
                formInline: {
                    psName: ''
                },
                powerStationData: [],
                currentPage: 1,
                pageSize: 5,
                totalCount: 0,
                loading: true,
            }
        },
        created() {
            this.initData()
        },
        methods: {
            handlePSEdit(index, row) {
                console.log(index, row)
                this.$emit('update:psId', row.psId);
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.initData();
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.initData();
            },
            onSubmit() {
                this.initData();
            },

            initData() {
                this.loading = true

                let params = {}
                params.page = this.currentPage
                params.limit = this.pageSize
                params.keyword = this.formInline.psName

                http.get('/index/getPsPage', {
                        params
                    })
                    .then(data => {
                        this.powerStationData = data.page.list
                        this.currentPage = data.page.currPage
                        // this.pageSize = data.page.pageSize
                        this.totalCount = data.page.totalCount
                        this.loading = false
                        // 处理数据  
                    })
                    .catch(error => {
                        console.error('请求失败:', error);
                        this.loading = false
                        // 处理错误  
                    });
            },

        }

    }
</script>

<style>

</style>