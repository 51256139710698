<template>
  <div>

    <div style="margin: 0 4em;">

      <h3>基本信息</h3>
      <BasicInfo></BasicInfo>
      <PowerStationTrendChart></PowerStationTrendChart>

      <h3 ref="stationInfo" style="margin-top: 2em;">站点列表</h3>
      <PowerStationRealTimeDataChart :psId="selectedPsId"></PowerStationRealTimeDataChart>

      <PowerStationInfo @update:psId="handlePsIdUpdate"></PowerStationInfo>

      <InverterInfo :psId="selectedPsId" @update:psKey="handlePsKeyUpdate"></InverterInfo>

      <h3 ref="inverterRealTimeTitle" style="margin-top: .2em;">
        <p v-if="selectedPsKey == null">
          逆变器实时数据指标图
        </p>

        <p v-else>
          逆变器{{selectedPsKey}}实时数据指标图
        </p>
      </h3>
      <InverterRealTimeChart :psKey="selectedPsKey"></InverterRealTimeChart>




      <h3 style="margin-top: 2em;">指标下载</h3>

      <el-row :gutter="10" style="margin-top: 1em;">
        <div style="margin-bottom: 1em;"> <i class="el-icon-info"></i>请注意：下载数据需提供您的单位、姓名、联系方式和用途</div>
        <el-button type="info" @click="openHistoryDownload">站点历史数据下载<i class="el-icon-download"
            style="font-size: 14px;"></i>
        </el-button>
        <el-button type="primary" :plain="true" @click="openTodayDowload">站点今日数据下载<i class="el-icon-download"
            style="font-size: 14px;"></i></el-button>
      </el-row>

      <el-drawer title="PCAS光伏智能数据下载" :visible.sync="dialog" direction="ltr" custom-class="demo-drawer" ref="drawer">
        <div class="demo-drawer__content">
          <el-form :model="visitorData" ref="visitorForm" :rules="rules">
            <el-form-item label="访客姓名" prop="name" :label-width="formLabelWidth">
              <el-input v-model="visitorData.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone" :label-width="formLabelWidth">
              <el-input v-model="visitorData.phone" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="企业单位" prop="company" :label-width="formLabelWidth">
              <el-input v-model="visitorData.company" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="数据使用用途" prop="purpose" :label-width="formLabelWidth">
              <el-input v-model="visitorData.purpose" autocomplete="off"></el-input>
            </el-form-item>

            <el-form-item label="数据类型" prop="downloadDataType">
              <el-radio-group v-model="visitorData.downloadDataType">
                <el-radio label="0">历史数据下载</el-radio>
                <el-radio label="1">今日实时数据下载</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <div class="demo-drawer__footer">
            <el-button @click="cancelForm">取 消</el-button>
            <el-button type="primary" @click="downloadSubmit" :loading="loading">
              {{ loading ? '提交中 ...' : '确 定' }}</el-button>
          </div>
        </div>
      </el-drawer>



    </div>

    <div class="c_copright">
      <div class="c_yqlj">
        "友情链接："
        <a href="http://www.fynu.edu.cn/" target="_blank" title=""
          onclick="_addDynClicks(&quot;wburl&quot;, 2019553950, 77780)">阜阳师范大学</a>
      </div>

      <p>CopyRight © 阜阳师范大学安徽省光伏产业共性技术研究中心 版权所有</p>

    </div>

  </div>


</template>

<script>
  /* eslint-disable */

  import InverterInfo from './InverterInfo.vue';
  import PowerStationInfo from './PowerStationInfo.vue';
  import BasicInfo from './BasicInfo.vue';
  import PowerStationTrendChart from './PowerStationTrendChart.vue';
  import PowerStationRealTimeDataChart from './PowerStationRealTimeDataChart.vue';
  import InverterRealTimeChart from './InverterRealTimeChart.vue';



  import http from '@/config/http';

  export default {
    data() {
      return {
        selectedPsId: null,
        selectedPsKey: null,

        dialog: false,
        loading: false,
        visitorData: {
          name: '',
          phone: '',
          company: '',
          purpose: '学术研究',
          downloadDataType: '0',
        },
        rules: {
          name: [{
              required: true,
              message: '请输入访客姓名',
              trigger: 'blur'
            },
            {
              min: 2,
              max: 10,
              message: '长度在 2 到 10 个字符',
              trigger: 'blur'
            }
          ],
          phone: [{
              required: true,
              message: '请输入联系电话',
              trigger: 'blur'
            },
            {
              pattern: /^1[3-9]\d{9}$/,
              message: '请输入有效的手机号码',
              trigger: 'blur'
            }
          ],
          company: [{
            required: true,
            message: '请输入企业单位',
            trigger: 'blur'
          }],
          purpose: [{
            required: true,
            message: '请输入数据使用用途',
            trigger: 'blur'
          }],
          downloadDataType: [{
            required: true,
            message: '请选择数据类型',
            trigger: 'change'
          }]
        },
        formLabelWidth: '100px',
      };
    },
    components: {
      InverterInfo,
      PowerStationInfo,
      BasicInfo,
      PowerStationTrendChart,
      PowerStationRealTimeDataChart,
      InverterRealTimeChart
    },
    mounted() {

    },
    methods: {

      async downloadSubmit() {
        try {
          // 验证表单  
          await new Promise((resolve, reject) => {
            this.$refs.visitorForm.validate((valid) => {
              if (!valid) {
                reject(new Error('表单未通过校验，请检查您的表单内容！'));
              } else {
                resolve();
              }
            });
          });


          await this.fetchData()

        } catch (error) {
          console.error('请求或处理错误:', error);
          this.loading = false;
          this.$message({
            message: error.message,
            type: "error"
          });
        }
      },

      async fetchData() {
        // 如果验证通过，则执行下载操作  
        this.loading = true;

        // 类型转化为数字
        this.visitorData.downloadDataType = Number(this.visitorData.downloadDataType)

        // 发送 HTTP 请求并处理响应  
        http.post('/index/visitorDownload', this.visitorData, {
          responseType: 'blob', // 设置为二进制响应  
          timeout: 60000
        }).then(res => {

          console.log('/index/visitorDownload/blob')
          console.log(res)

          // 检查响应是否为 Blob 对象  
          if (res instanceof Blob && res) {

            if (res.size < 200) {
              //throw new Error('资源不存在，请稍后再试或联系管理员！');

              this.$message({
                message: '资源不存在，请稍后再试或联系管理员！',
                type: 'error',
                onClose: () => {
                  this.cancelForm();
                }
              })

              return false

            }
            // 处理下载  
            const url = window.URL.createObjectURL(res);


            const link = document.createElement('a');
            link.href = url;
            let fileName = "history_data.zip"
            if (this.visitorData.downloadDataType === 1) {
              fileName = "realtime_data.zip"
            }
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

            // 清理  
            window.URL.revokeObjectURL(url);
            link.remove();

            this.$message({
              message: '请求处理成功，感谢您的支持！',
              type: "success",
              onClose: () => {
                this.cancelForm();
              }
            });

          }else {
            console.log('/index/visitorDownload res error')
          }

        })

      },


      cancelForm() {
        this.loading = false;
        this.dialog = false;
      },

      handlePsIdUpdate(psId) {
        this.selectedPsId = psId; // 更新selectedPsId  
        this.$nextTick(() => {
          const stationInfo = this.$refs.stationInfo;
          if (stationInfo) {
            stationInfo.scrollIntoView({
              behavior: 'smooth'
            }); // 平滑滚动  
          }
        });
      },

      handlePsKeyUpdate(psKey) {

        console.log(psKey + ' - 被选中')
        this.selectedPsKey = psKey; // 更新selectedPsKey 
        this.$nextTick(() => {
          const inverterRealTimeTitle = this.$refs.inverterRealTimeTitle;
          if (inverterRealTimeTitle) {
            inverterRealTimeTitle.scrollIntoView({
              behavior: 'smooth'
            }); // 平滑滚动  
          }
        });
      },



      openHistoryDownload() {
        this.dialog = true
        this.visitorData.downloadDataType = '0'

      },

      openTodayDowload() {
        this.dialog = true
        this.visitorData.downloadDataType = '1'
      }
    },
  };
</script>

<style lang="scss">
  .like {
    cursor: pointer;
    font-size: 25px;
    display: inline-block;
  }

  i {
    font-size: 24px;
    margin-left: 0.2em;
  }

  .el-form-item {
    padding: 2px 10px;
  }

  .el-drawer__header span {
    font-size: 22px;
    font-weight: 800;
    padding-left: 10px;
  }

  .demo-drawer__footer {
    display: flex;
    justify-content: end;
    padding: 0 20px;
  }

  .blockinfo {
    padding: 0.4em;
    // margin: 0 2.4em;
    border-radius: 4px;
    background-color: #f7f7f9;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  }

  .chart-box {
    min-height: 400px;
  }

  .c_copright {
    background: #08539f;
    height: 80px;
    line-height: 55px;
    text-align: center;
    min-height: 200px;
    margin-top: 4em;
    width: 100%;
    clear: both;
    padding: 15px 0;
    color: #ffffff;
  }

  .c_yqlj {
    color: #ffffff;
    font-size: 16px;
    line-height: 40px;
    height: auto;
    overflow: hidden;
    width: 100%;
    text-align: center;
  }

  .c_yqlj a {
    text-decoration: none;
    color: #ffffff;
  }

  .c_copright p {
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    color: #ffffff;
    margin: 0;
    padding: 0;
  }
</style>


/* eslint-enable */