<template>
    <div style="margin: 1em 0;">
        <el-row :gutter="10">
            <el-col :span="8">
                <el-card>
                    <div ref="powerGenChart" class="chart-box">
                    </div>
                </el-card>
            </el-col>

            <el-col :span="8">
                <el-card>
                    <div ref="mpptChart" class="chart-box">
                    </div>
                </el-card>
            </el-col>

            <el-col :span="8">
                <el-card>
                    <div ref="groupDataChart" class="chart-box">
                    </div>
                </el-card>
            </el-col>
        </el-row>

        <div style="margin: 1em 0;">
            <el-row :gutter="10">
                <el-col :span="8">
                    <el-card>
                        <div ref="aPhaseChart" class="chart-box">
                        </div>
                    </el-card>
                </el-col>

                <el-col :span="8">
                    <el-card>
                        <div ref="bPhaseChart" class="chart-box">
                        </div>
                    </el-card>
                </el-col>

                <el-col :span="8">
                    <el-card>
                        <div ref="cPhaseChart" class="chart-box">
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </div>


    </div>
</template>

<script>
    import * as echarts from "echarts";
    import http from '@/config/http';

    export default {

        props: {
            psKey: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                psKeyStr: '',
                powerGenlegendData: ['发电量', '机内温度'],
                timeData: [],
                powerGenSeriesData: [],

                mpptlegendData: ['MPPT1', 'MPPT2', 'MPPT3', 'MPPT4'],
                mpptSeriesData: [],

                groupDatalegendData: ['组串1', '组串2', '组串3', '组串4'],
                groupDataSeriesData: [],

                phaseLegend: ['电压', '电流'],
                threePhaseSeriesData: [],


            }
        },

        mounted() {
            this.initData()
        },

        methods: {
            initData(psKey) {

                let params = {}
                if (psKey != null) {
                    params.psKey = psKey
                }

                http.get('/index/getInverterRealTimeData', {
                        params
                    })
                    .then(data => {
                        this.psKeyStr = data.res.psKey
                        this.timeData = data.res.timeData
                        this.powerGenSeriesData = data.res.powerGen
                        this.mpptSeriesData = data.res.mppt
                        this.groupDataSeriesData = data.res.groupData

                        this.threePhaseSeriesData = data.res.threePhase

                        this.initChart()


                    })
                    .catch(error => {
                        console.error('请求失败:', error);
                        this.loading = false
                    });
            },

            initChart() {
                this.initPowerGenChart()
                this.initMpptChart()
                this.initGroupDataChart()

                this.initAPhaseChart()
                this.initBPhaseChart()
                this.initCPhaseChart()
            },

            initPowerGenChart() {

                const powerGenChart = echarts.init(this.$refs.powerGenChart);
                powerGenChart.setOption({

                    legend: {
                        data: this.powerGenlegendData,
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross'
                        },
                        formatter: function (params) {

                            let returnStr = ''
                            if (params[0] != null) {
                                returnStr += '时间: ' + params[0].axisValue + ', ' + params[0].seriesName +
                                    ':' + params[0].value;
                            }
                            if (params[1] != null) {
                                returnStr += ', ' + params[1].seriesName + ':' + params[1].value;
                            }

                            return returnStr;
                        }
                    },
                    yAxis: [{
                            type: 'value',
                            name: '机内温度',
                            position: 'left',
                            axisLabel: {
                                formatter: '{value} °C'
                            }
                        },
                        {
                            type: 'value',
                            name: '发电量',
                            position: 'right',
                            axisLabel: {
                                formatter: '{value} W'
                            }
                        }
                    ],
                    xAxis: {
                        type: 'category',
                        splitLine: {
                            show: true
                        },
                        data: this.timeData
                    },

                    series: [{
                            name: '机内温度',
                            type: 'line',
                            yAxisIndex: 0,
                            data: this.powerGenSeriesData.map(item => parseFloat(item.p4))
                        },
                        {
                            name: '发电量',
                            type: 'bar',
                            yAxisIndex: 1,
                            data: this.powerGenSeriesData.map(item => parseFloat(item.p1))
                        }
                    ]
                });


            },

            initMpptChart() {

                console.log('initMpptChart')

                const mpptChart = echarts.init(this.$refs.mpptChart);
                mpptChart.setOption({
                    // 图表配置和数据
                    legend: {
                        data: this.mpptlegendData,
                    },
                    tooltip: {
                        trigger: 'axis', // 或者 'axis'，取决于你的图表类型和数据结构  
                        axisPointer: {
                            type: 'cross'
                        },
                        formatter: function (params) {
                            console.log(params)
                            // let data0 = params[0]
                            // let data1 = params[1]
                            // return '时间: ' + data0.axisValue + ', 电压:' + data0.value +
                            //     ', 电流:' + data1.value;
                        }
                    },
                    yAxis: [{
                            type: 'value',
                            name: '电压',
                            position: 'left',
                            axisLabel: {
                                formatter: '{value} V'
                            }

                        },
                        {
                            type: 'value',
                            name: '电流',
                            position: 'right',
                            axisLabel: {
                                formatter: '{value} A'
                            }
                        }
                    ],
                    xAxis: {
                        type: 'category',
                        splitLine: {
                            show: true
                        },
                        data: this.timeData
                    },

                    series: [{
                            name: 'MPPT1',
                            type: 'line',
                            yAxisIndex: 0,
                            data: this.mpptSeriesData.map(item => parseFloat(item.p5))
                        },
                        {
                            name: 'MPPT1',
                            type: 'bar',
                            yAxisIndex: 1,
                            data: this.mpptSeriesData.map(item => parseFloat(item.p6))
                        },

                        {
                            name: 'MPPT2',
                            type: 'line',
                            yAxisIndex: 0,
                            data: this.mpptSeriesData.map(item => parseFloat(item.p7))
                        },
                        {
                            name: 'MPPT2',
                            type: 'bar',
                            yAxisIndex: 1,
                            data: this.mpptSeriesData.map(item => parseFloat(item.p8))
                        },

                        {
                            name: 'MPPT3',
                            type: 'line',
                            yAxisIndex: 0,
                            data: this.mpptSeriesData.map(item => parseFloat(item.p9))
                        },
                        {
                            name: 'MPPT3',
                            type: 'bar',
                            yAxisIndex: 1,
                            data: this.mpptSeriesData.map(item => parseFloat(item.p10))
                        },

                        {
                            name: 'MPPT4',
                            type: 'line',
                            yAxisIndex: 0,
                            data: this.mpptSeriesData.map(item => parseFloat(item.p45))
                        },
                        {
                            name: 'MPPT4',
                            type: 'bar',
                            yAxisIndex: 1,
                            data: this.mpptSeriesData.map(item => parseFloat(item.p46))
                        },
                    ]
                });


            },

            initGroupDataChart() {

                const groupDataChart = echarts.init(this.$refs.groupDataChart);
                groupDataChart.setOption({
                    // 图表配置和数据
                    legend: {
                        data: this.groupDatalegendData,
                    },
                    tooltip: {
                        trigger: 'axis', // 或者 'axis'，取决于你的图表类型和数据结构  
                        axisPointer: {
                            type: 'cross'
                        },
                        formatter: function (params) {
                            console.log(params)
                            // let data0 = params[0]
                            // let data1 = params[1]
                            // return '时间: ' + data0.axisValue + ', 电压:' + data0.value +
                            //     ', 电流:' + data1.value;
                        }
                    },
                    yAxis: [{
                            type: 'value',
                            name: '电压',
                            position: 'left',
                            axisLabel: {
                                formatter: '{value} V'
                            }

                        },
                        {
                            type: 'value',
                            name: '电流',
                            position: 'right',
                            axisLabel: {
                                formatter: '{value} A'
                            }
                        }
                    ],
                    xAxis: {
                        type: 'category',
                        splitLine: {
                            show: true
                        },
                        data: this.timeData
                    },

                    series: [{
                            name: '组串1',
                            type: 'line',
                            yAxisIndex: 0,
                            data: this.groupDataSeriesData.map(item => parseFloat(item.p96))
                        },
                        {
                            name: '组串2',
                            type: 'line',
                            yAxisIndex: 0,
                            data: this.groupDataSeriesData.map(item => parseFloat(item.p97))
                        },

                        {
                            name: '组串3',
                            type: 'line',
                            yAxisIndex: 0,
                            data: this.groupDataSeriesData.map(item => parseFloat(item.p98))
                        },
                        {
                            name: '组串4',
                            type: 'line',
                            yAxisIndex: 0,
                            data: this.groupDataSeriesData.map(item => parseFloat(item.p99))
                        },

                        {
                            name: '组串1',
                            type: 'bar',
                            yAxisIndex: 1,
                            data: this.groupDataSeriesData.map(item => parseFloat(item.p70))
                        },
                        {
                            name: '组串2',
                            type: 'bar',
                            yAxisIndex: 1,
                            data: this.groupDataSeriesData.map(item => parseFloat(item.p71))
                        },

                        {
                            name: '组串3',
                            type: 'bar',
                            yAxisIndex: 1,
                            data: this.groupDataSeriesData.map(item => parseFloat(item.p72))
                        },
                        {
                            name: '组串4',
                            type: 'bar',
                            yAxisIndex: 1,
                            data: this.groupDataSeriesData.map(item => parseFloat(item.p73))
                        },
                    ]
                });


            },

            initAPhaseChart() {

                const aPhaseChart = echarts.init(this.$refs.aPhaseChart);
                aPhaseChart.setOption({
                    title: {
                        text: 'A相'
                    },
                    legend: {
                        data: this.phaseLegend,
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross'
                        },
                        formatter: function (params) {

                            let returnStr = ''
                            if (params[0] != null) {
                                returnStr += '时间: ' + params[0].axisValue + ', ' + params[0].seriesName +
                                    ':' + params[0].value;
                            }
                            if (params[1] != null) {
                                returnStr += ', ' + params[1].seriesName + ':' + params[1].value;
                            }

                            return returnStr;
                        }
                    },
                    yAxis: [{
                            type: 'value',
                            name: '电压',
                            position: 'left',
                            axisLabel: {
                                formatter: '{value} V'
                            }
                        },
                        {
                            type: 'value',
                            name: '电流',
                            position: 'right',
                            axisLabel: {
                                formatter: '{value} A'
                            }
                        }
                    ],
                    xAxis: {
                        type: 'category',
                        splitLine: {
                            show: true
                        },
                        data: this.timeData
                    },

                    series: [{
                            name: '电压',
                            type: 'line',
                            yAxisIndex: 0,
                            data: this.threePhaseSeriesData.map(item => parseFloat(item.p18))
                        },
                        {
                            name: '电流',
                            type: 'bar',
                            yAxisIndex: 1,
                            data: this.threePhaseSeriesData.map(item => parseFloat(item.p21))
                        }
                    ]
                });


            },

            initBPhaseChart() {

                const bPhaseChart = echarts.init(this.$refs.bPhaseChart);
                bPhaseChart.setOption({
                    title: {
                        text: 'B相'
                    },
                    legend: {
                        data: this.phaseLegend,
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross'
                        },
                        formatter: function (params) {

                            let returnStr = ''
                            if (params[0] != null) {
                                returnStr += '时间: ' + params[0].axisValue + ', ' + params[0].seriesName +
                                    ':' + params[0].value;
                            }
                            if (params[1] != null) {
                                returnStr += ', ' + params[1].seriesName + ':' + params[1].value;
                            }

                            return returnStr;
                        }
                    },
                    yAxis: [{
                            type: 'value',
                            name: '电压',
                            position: 'left',
                            axisLabel: {
                                formatter: '{value} V'
                            }
                        },
                        {
                            type: 'value',
                            name: '电流',
                            position: 'right',
                            axisLabel: {
                                formatter: '{value} A'
                            }
                        }
                    ],
                    xAxis: {
                        type: 'category',
                        splitLine: {
                            show: true
                        },
                        data: this.timeData
                    },

                    series: [{
                            name: '电压',
                            type: 'line',
                            yAxisIndex: 0,
                            data: this.threePhaseSeriesData.map(item => parseFloat(item.p19))
                        },
                        {
                            name: '电流',
                            type: 'bar',
                            yAxisIndex: 1,
                            data: this.threePhaseSeriesData.map(item => parseFloat(item.p22))
                        }
                    ]
                });


            },

            initCPhaseChart() {

                const cPhaseChart = echarts.init(this.$refs.cPhaseChart);
                cPhaseChart.setOption({
                    title: {
                        text: 'C相'
                    },
                    legend: {
                        data: this.phaseLegend,
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross'
                        },
                        formatter: function (params) {

                            let returnStr = ''
                            if (params[0] != null) {
                                returnStr += '时间: ' + params[0].axisValue + ', ' + params[0].seriesName +
                                    ':' + params[0].value;
                            }
                            if (params[1] != null) {
                                returnStr += ', ' + params[1].seriesName + ':' + params[1].value;
                            }

                            return returnStr;
                        }
                    },
                    yAxis: [{
                            type: 'value',
                            name: '电压',
                            position: 'left',
                            axisLabel: {
                                formatter: '{value} V'
                            }
                        },
                        {
                            type: 'value',
                            name: '电流',
                            position: 'right',
                            axisLabel: {
                                formatter: '{value} A'
                            }
                        }
                    ],
                    xAxis: {
                        type: 'category',
                        splitLine: {
                            show: true
                        },
                        data: this.timeData
                    },

                    series: [{
                            name: '电压',
                            type: 'line',
                            yAxisIndex: 0,
                            data: this.threePhaseSeriesData.map(item => parseFloat(item.p20))
                        },
                        {
                            name: '电流',
                            type: 'bar',
                            yAxisIndex: 1,
                            data: this.threePhaseSeriesData.map(item => parseFloat(item.p23))
                        }
                    ]
                });


            },



        },

        watch: {
            psKey(newVal) {
                console.log('watch', newVal)
                if (newVal) {
                    this.initData(newVal);
                }
            }
        },
    }
</script>

<style>

</style>