<template>
    <div>
        <el-row :gutter="10" style="display: flex; justify-content: space-between;">
            <el-col :span="6">
                <div class="blockinfo">
                    <el-statistic :title="psCountTitle" :value="psCount">
                        <template slot="suffix">
                            <i class="el-icon-sunrise"></i>
                        </template>
                    </el-statistic>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="blockinfo">
                    <el-statistic :title="psStatusTitle">
                        <template slot="formatter">
                            {{ psStatus }}
                        </template>
                        <template slot="suffix">
                            <i class="el-icon-data-line"></i>
                        </template>
                    </el-statistic>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="blockinfo">
                    <el-statistic :title="inverterCountTitle" :value="inverterCount">
                        <template slot="suffix">
                            <i class="el-icon-sunrise-1"></i>
                        </template>
                    </el-statistic>
                </div>
            </el-col>

            <el-col :span="6">
                <div class="blockinfo">
                    <el-statistic :value="inverterStatus" :title="inverterStatusTitle">
                        <template slot="formatter">
                            {{ inverterStatus }}
                        </template>
                        <template slot="suffix">
                            <i class="el-icon-pie-chart" />
                        </template>
                    </el-statistic>
                </div>
            </el-col>
        </el-row>


        <el-row :gutter="10">

            <el-col :span="12" style="padding: .4em .2em;">
                <el-card>
                    <div ref="powerStationChart" class="chart-box"></div>
                </el-card>
            </el-col>

            <el-col :span="12" style="padding: .4em .2em;">
                <el-card>
                    <div ref="inverterStatusChart" class="chart-box"></div>
                </el-card>
            </el-col>

        </el-row>
    </div>
</template>

<script>
    import * as echarts from "echarts";
    import http from '@/config/http';

    export default {

        data() {
            return {
                psCount: 0,
                inverterCount: 0,
                inverterStatus: "",
                psStatus: "",
                psCountTitle: "已统计电站数量",
                inverterCountTitle: "已统计逆变器数量",
                psStatusTitle: "电站状态",
                inverterStatusTitle: "逆变器状态",

                powerStationStatusData: [{
                        value: 0,
                        name: "正常运行",
                        itemStyle: {
                            color: '#5470C6'
                        }
                    },
                    {
                        value: 0,
                        name: "故障",
                        faultList: [],
                        itemStyle: {
                            color: '#F85F5C'
                        }
                    },

                ],
                inverterTotalStatusData: [{
                        value: 0,
                        name: '正常逆变器',
                        itemStyle: {
                            color: '#5470C6'
                        }
                    },
                    {
                        value: 0,
                        name: '故障逆变器',
                        itemStyle: {
                            color: '#F85F5C'
                        }
                    },

                ],

                inverterOuterChartData: [],
            }
        },

        mounted() {
            this.initData();
            this.initCharts();
        },
        methods: {
            initData() {
                this.initPSStatus()
                this.initInverterStatus()
                this.getFaultyPSList()
                this.getFaultyInvertersInPS()
            },


            initPSStatus() {
                http.get('/index/psStatus')
                    .then(data => {
                        this.psCount = data.res.psCount
                        this.psStatus = data.res.psStatus
                        let arr = this.psStatus.match(/\d+/g).map(Number)

                        if (arr.length == 2) {
                            this.powerStationStatusData[0].value = arr[0]
                            this.powerStationStatusData[1].value = arr[1]

                            this.initPowerStationChart()
                        }


                    })
                    .catch(error => {
                        console.error('请求失败:', error);
                    });
            },

            initInverterStatus() {
                http.get('/index/inverterStatus')
                    .then(data => {
                        this.inverterCount = data.res.inverterCount
                        this.inverterStatus = data.res.inverterStatus
                        let arr = this.inverterStatus.match(/\d+/g).map(Number)

                        if (arr.length == 2) {
                            this.inverterTotalStatusData[0].value = arr[0]
                            this.inverterTotalStatusData[1].value = arr[1]

                            this.initInverterStatusChart()
                        }

                    })
                    .catch(error => {
                        console.error('请求失败:', error);
                    });
            },

            getFaultyPSList() {
                http.get('/index/faultyPSList')
                    .then(data => {
                        this.powerStationStatusData[1].faultList = data.res;
                    })
                    .catch(error => {
                        console.error('请求失败:', error);
                    });
            },

            getFaultyInvertersInPS() {
                http.get('/index/faultyInvertersInPS')
                    .then(data => {
                        const inverterDataMap = data.res;
                        let arr = []
                        for (const key in inverterDataMap) {
                            arr.push({
                                name: key, // 使用HashMap的键作为name  
                                value: inverterDataMap[key] // 使用HashMap的值作为value  
                            });
                        }

                        this.inverterOuterChartData = arr

                    })
                    .catch(error => {
                        console.error('请求失败:', error);
                    });

            },

            initCharts() {

                this.initPowerStationChart()
                this.initInverterStatusChart()

            },

            initPowerStationChart() {
                // 电站状态饼图
                const powerStationChart = echarts.init(this.$refs.powerStationChart);

                powerStationChart.setOption({
                    title: {
                        text: "电站状态",
                    },
                    label: {
                        show: true,
                        position: 'outer',
                        formatter: function (params) {
                            // 如果值为0，则不显示标签  
                            if (params.value === 0) {
                                return '';
                            }

                            if (params.data.faultList != null && params.data.faultList.length > 0) {
                                return '故障站点' + (params.percent).toFixed(2) + '%)';
                            }
                            // 否则返回默认的标签内容  
                            return params.name + ': ' + params.value + ' (' + (params.percent).toFixed(2) +
                                '%)';
                        }
                    },

                    tooltip: {
                        trigger: 'item',
                        // formatter: '{a} <br/>{b} : {c} ({d}%)'
                        formatter: function (params) {

                            // 如果值为0，则不显示标签  
                            if (params.value === 0) {
                                return '';
                            }

                            if (params.data.faultList != null && params.data.faultList.length > 0) {
                                let str = '';
                                params.data.faultList.forEach(item => {
                                    str += item + ","
                                });
                                return '故障站点列表[' + str.replace(/^,+|,+$/g, '') + ']';
                            }
                            // 否则返回默认的标签内容  
                            return params.name + ': ' + params.value + ' (' + (params.percent).toFixed(2) +
                                '%)';
                        }
                    },
                    series: [{
                        name: "状态",
                        type: "pie",
                        radius: "80%",
                        data: this.powerStationStatusData,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    }, ],
                });


            },

            initInverterStatusChart() {
                // 逆变器状态图表
                const inverterStatusChart = echarts.init(this.$refs.inverterStatusChart);

                inverterStatusChart.setOption({

                    title: {
                        text: "电站逆变器状态",
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b} : {c} ({d}%)'
                    },

                    legend: {
                        data: ['正常逆变器', '故障逆变器'],
                        orient: 'vertical',
                        left: 'right',
                    },
                    series: [{
                            name: "电站逆变器状态",
                            type: "pie",
                            radius: [0, '40%'],
                            label: {
                                position: 'inner',
                                fontSize: 12,
                            },
                            labelLine: {
                                show: false
                            },
                            data: this.inverterTotalStatusData,

                        },
                        {
                            name: '故障逆变器数量',
                            type: 'pie',
                            radius: ['55%', '85%'],
                            label: {
                                formatter: function (params) {
                                    return params.name + '当前有' + params.value + '个逆变器发生异常';
                                },
                            },
                            data: this.inverterOuterChartData
                        }

                    ]
                })
            },
        }

    }
</script>

<style>

</style>